@tailwind base;
@tailwind components;
@tailwind utilities;

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a {
  @apply text-primary no-underline;
}

a:hover {
  @apply cursor-pointer;
}

h1 {
  @apply font-bold text-2xl leading-10 sm:text-3xl sm:leading-10 lg:text-4xl;
}

h2 {
  @apply font-bold text-xl leading-9 sm:text-2xl sm:leading-10 lg:text-3xl;
}

h3 {
  @apply font-bold text-base sm:text-xl lg:text-2xl leading-7;
}

h4 {
  @apply font-semibold text-sm sm:text-base leading-7 text-gray-700;
}

.scroll-margin-top {
  @apply scroll-mt-16;
}

.text-gradient {
  background-image: linear-gradient(to right bottom, #002f9a, #1735a2, #253ba9, #3042b1, #3a48b9, #3b4abe, #3d4cc2, #3e4ec7, #364cca, #2c49ce, #1f47d1, #0645d4);  color: transparent;
  background-clip: text;
}

.bg-gradient {
  background: linear-gradient(to right bottom, #002f9a, #1735a2, #253ba9, #3042b1, #3a48b9, #3b4abe, #3d4cc2, #3e4ec7, #364cca, #2c49ce, #1f47d1, #0645d4);
}

@layer base {
  html {
    @apply scroll-smooth box-border;
  }

  body {
    @apply bg-gradient scroll-smooth box-border;
  }

  #__next {
    @apply overflow-hidden scroll-smooth box-border min-h-screen relative;
  }
}
